@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Josefin+Sans');

// $primary: #a51616;
$primary: #ff7144; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}

nav {
	z-index: 1000;
}
h1,h2,h3 {
	font-family: 'Abril Fatface', cursive;
}
p {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 22px;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}


input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

.banner {
	background: url(../img/banner.jpg) no-repeat;
	height: 900px;
	background-size: cover;
	background-position: 50% 50%;
	padding: 350px 0;
	@media (max-width: 767px) {
		height: auto;
		padding: 100px 0;
	}
	h1 {
		color: white;
		font-size: 45px;
	}
}
.banner2 {
	background: url(../img/banner2.jpg) no-repeat;
	height: auto;
	padding: 200px 0;
	background-position: 50% 50%;
	background-attachment: fixed;
	background-size: cover;
	@media (max-width: 1024px) {
		background-attachment: initial !important;
		background-position: 60% 50%;
		padding: 50px 0;
	}
	h1,p {
		color: white;
	}
}
hr {
	max-width: 300px;
}

.logo {
	max-width: 200px;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}

a.btn.btn-large {
	@include btn1;
}

.container-fluid {
	.col-md-4 {
		padding: 0;
	}
}

.overlay {
	position: relative;
	overflow: hidden;
	padding: 0;
	margin: 0;
	img {
	width: 100%;
}
	h1, p {
		position: absolute;
	}
	h1 {
	top: 40%;
	color: white;
	opacity: 0;
	 transition: opacity 0.5s ease;;
	 z-index: 2;
	 width: 100%;
	 @media (max-width: 1024px) {
			opacity: 1;
	}
}
&:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	@media (max-width: 1024px) {
		background: rgba(0, 0, 0, 0.6);
		opacity: 1;
}
}
&:hover {
	h1, p {
		opacity: 1
	}
	&:after {
		opacity: 1;
		background: rgba(0, 0, 0, 0.6);
	}
}
}
.pic1 {
	background: url(../img/couple.jpg) no-repeat;
	background-size: cover;
	height: 300px;
	background-position: 50% 50%;
	@media (max-width: 991px) {
		height: 400px;
	}
	@media (max-width: 500px) {
		background-position: 80% 50%;
	}
}

.pic2 {
	background: url(../img/couple2.jpg) no-repeat;
	background-size: cover;
	height: 300px;
	background-position: 50% 50%;
	@media (max-width: 991px) {
		height: 400px;
	}
}

.pic3 {
	background: url(../img/couple3.jpg) no-repeat;
	background-size: cover;
	height: 300px;
	background-position: 50% 50%;
	@media (max-width: 991px) {
		height: 400px;
	}
	@media (max-width: 500px) {
		background-position: 30% 50%;
	}
}

.nav.navbar-nav {
	margin-top: 25px;
	@media (max-width: 767px) {
		margin-top: 0;
	}
}

